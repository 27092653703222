import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  Button,
  Card,
  CardMedia,
} from "@mui/material";

const App: React.FC = () => {
  return (
    <div style={{ backgroundColor: "#fff", minHeight: "100vh", color: "#333" }}>
      {/* Header */}
      <AppBar
        position="static"
        elevation={0}
        style={{
          backgroundColor: "white",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            style={{
              color: "#1976d2",
              flexGrow: 1,
              fontWeight: 600,
            }}
          >
            I Edu Tales
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            href="mailto:iedutales@gmail.com"
            style={{
              borderColor: "#1976d2",
              color: "#1976d2",
            }}
          >
            Contact Us
          </Button>
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <Container
        maxWidth="md"
        style={{ marginTop: "2rem", paddingBottom: "2rem" }}
      >
        {/* Introduction Section */}
        <Box textAlign="center" mb={6}>
          <Typography
            variant="h4"
            gutterBottom
            style={{
              fontWeight: 700,
              color: "#1976d2",
            }}
          >
            Transforming Education Through Storytelling
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontSize: "1.2rem",
              lineHeight: "1.8",
              color: "#555",
              maxWidth: "80%",
              margin: "0 auto",
            }}
          >
            Immersive, personalised experiences for learning and creativity.
          </Typography>
        </Box>

        {/* Video Section */}
        <Box textAlign="center" mb={6}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              fontWeight: 600,
              color: "#1976d2",
            }}
          >
            Watch Our Introduction
          </Typography>
          <video
            controls
            style={{
              width: "100%",
              maxWidth: "800px",
              borderRadius: "8px",
              marginTop: "1rem",
            }}
          >
            <source src="/final_version.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>

        {/* Vision Section */}
        <Box textAlign="center" mb={6}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              fontWeight: 600,
              color: "#1976d2",
            }}
          >
            Our Vision
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontSize: "1.1rem",
              lineHeight: "1.8",
              color: "#555",
              marginBottom: "1rem",
            }}
          >
            Stories are the foundation of children's imagination, education, and
            connection. In today's digital world, storytelling has the potential
            to become more interactive, personalised, and educational than ever
            before.
          </Typography>
        </Box>

        {/* About Us Section */}
        {/* About Us Section */}
        <Box textAlign="center" mb={6}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              fontWeight: 600,
              color: "#1976d2",
            }}
          >
            About Us
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontSize: "1.1rem",
              lineHeight: "1.8",
              color: "#555",
              marginBottom: "1rem",
            }}
          >
            We are a passionate team of innovators dedicated to transforming
            education through the power of storytelling. By blending technology,
            creativity, and education, we create immersive and personalised
            experiences that inspire children to imagine, learn, and thrive in a
            rapidly evolving world.
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontSize: "1.1rem",
              lineHeight: "1.8",
              color: "#555",
              marginBottom: "1rem",
            }}
          >
            For more information, contact us at:{" "}
            <a
              href="mailto:iedutales@gmail.com"
              style={{
                color: "#1976d2",
              }}
            >
              iedutales@gmail.com
            </a>
          </Typography>
        </Box>

        <Box textAlign="center">
          <Card
            elevation={0}
            style={{
              maxWidth: "100%",
              borderRadius: "8px",
              margin: "0 auto",
              overflow: "hidden",
            }}
          >
            <CardMedia
              component="img"
              image="/dragon_move.gif"
              alt="Interactive storytelling GIF"
              style={{
                width: "100%",
              }}
            />
          </Card>
        </Box>
      </Container>
    </div>
  );
};

export default App;
